'use strict';

document.addEventListener('DOMContentLoaded', function() {

  $('.index-jimin_card_heading').on('click', function() {
    // 次の兄弟要素にクラスを追加する
    $(this).parent('.index-jimin_card').toggleClass('is-open');
    $(this).next('.index-jimin_card_body').slideToggle(200);
  });

  /**
   * パラメータ取得関数
   * @argument name 取得したいパラメータ名
   * @argument url 表示ページ以外のURLから取得したい場合
   */
  function getParam(name, url) {
    if (!url) url = window.location.search;
    name = name.replace(/[\[\]]/g, "\\$&");
    let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
    let results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    let result = decodeURIComponent(results[2].replace(/\+/g, " "));
    return htmlspecialchars(result);
  }

  
  /**
   * エスケープ関数
   * @argument unsafeText エスケープしたい文字列
   */
  function htmlspecialchars(unsafeText){
    if(typeof unsafeText !== 'string'){
      return unsafeText;
    }
    return unsafeText.replace(/[&'`"<>]/g, function(match) {
        return {
          '&': '&amp;',
          "'": '&#x27;',
          '`': '&#x60;',
          '"': '&quot;',
          '<': '&lt;',
          '>': '&gt;',
        }[match]
      }
    );
  }


  /**
   * アンカースクロール
   * @variable headerElement 固定ヘッダーセレクタ
   * @variable headerElement スクロール速度
   */
  const headerClass = '.l-header';

  let scroll = new SmoothScroll('a[href*="#"]', {
    speedAsDuration: true,
    speed: 500,
    header: headerClass,
    easing: 'easeInOutQuint'
  });

  // ページ表示時のアンカー位置にヘッダーの高さを考慮する
  const headerElement = document.querySelector(headerClass);
  let headerElementHeight = 0;

  // ロード時にヘッダーが存在していれば高さを取得
  if ( headerElement !== null ) {
    headerElementHeight = headerElement.offsetHeight;
  }
  // ハッシュ値があればロード時に高さを考慮した位置へスクロール位置を差し替え
  if ( window.location.hash !== '' ) {
    window.addEventListener('load', function() {
      const target = document.querySelector(window.location.hash);
      if ( target !== null ) {
        const position = target.offsetTop - headerElementHeight;
        document.documentElement.scrollTop = position;
      }
    });
  }


  /**
   * Javascriptによるメディアクエリ
   * @variable mediaQueryList PC以上の場合
   */
  const mediaQueryList = window.matchMedia('(min-width: 751px)');
  handleMediaQuery(mediaQueryList);

  function handleMediaQuery(e) {
    if (e.matches) {
      // min-width: 751px (PC)


    } else {
      // max-width: 750px (SP)

    }
  }
  mediaQueryList.addEventListener('change', handleMediaQuery);


  /**
   * スマートフォン表示でブラウザUIを考慮する処理
   * @memo 下記CSSを全画面表示したい要素に設定する
   * @memo min-height: 100vh; min-height: calc(var(--vh, 1vh) * 100);
   */
  const setFillHeight = function() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', vh + 'px');
  }

  // 画面のサイズ変動があった時に高さを再計算する
  window.addEventListener('resize', setFillHeight);

  // 初期化
  setFillHeight();


  /**
   * アクセシビリティを考慮したスライダー
   * https://ja.splidejs.com/
   */
  /*
  <div class="splide js-slider" aria-label="screen-read-name">
    <div class="splide__track">
      <ul class="splide__list">
        <li class="splide__slide"></li>
      </ul>
    </div>
    <div class="splide__arrows">
      <button class="splide__arrow splide__arrow--prev">Prev</button>
      <ul class="splide__pagination"></ul>
      <button class="splide__arrow splide__arrow--next">Next</button>
    </div>
  </div>
  */
  if ( document.querySelector('.js-slider') !== null ) {
    let jsSlider = new Splide('.js-slider', {
      perPage       : 1,      // 1ページの表示数
      perMove       : 1,      // 1度にスライドを何枚移動するか
      speed         : 800,    // 移動時間（ミリ秒単位）
      type          : 'loop', // ループする場合
      interval      : 4000,   // 自動再生の間隔（ミリ秒単位）
      pauseOnHover  : false,  // マウスホバー時に自動再生をストップさせない
      resetProgress : false,  // 自動再生ストップ時、経過時間を破棄せず続行する
      updateOnMove  : true,   // is-activeクラスを移動前に更新する
      gap           : '10px', // スライド間の余白（cssと同様）
      easing        : 'cubic-bezier(0.25, 1, 0.5, 1)', // 移動アニメーション
      focus         : 'center',

      // インビュー時から自動再生をスタート（拡張機能：splide-extension-intersection.js）
      autoplay: 'pause',
      intersection: {
        inView: {
          autoplay: true,
        },
        outView: {
          autoplay: false,
        },
        once: true, // 一度画面に表示されたら監視を停止する
      },

      // 動き続けるスライダーを実装（拡張機能：splide-extension-auto-scroll.js）
      autoScroll: {
        speed: 1, // 負の値で流れる向きが右へ変更
        pauseOnHover: false,
        pauseOnFocus: false,
      },

      // メディアクエリ設定（初期：751px以上の場合、スライダーを動作させない）
      // mediaQuery: 'min',
      // breakpoints: {
      //   751: {
      //     destroy: true,
      //   },
      // },
    });

    // スライダーに過不足がある場合にスライダーUIを停止・排除する
    jsSlider.on('overflow', function ( isOverflow ) {
      jsSlider.go(0);
      jsSlider.options = {
        arrows    : isOverflow,
        pagination: isOverflow,
        drag      : isOverflow,
        clones    : isOverflow ? undefined : 0,
      };
    } );

    // スライダーを実行
    jsSlider.mount();
    // jsSlider.mount(window.splide.Extensions);

  }

    
  gsap.to('.index-message_ornament-01', {
    scrollTrigger: {
      trigger: '.index-message',
      start: '40% center',
      toggleClass: { targets: ".index-message_ornament-01", className: "is-scrolled" },
      once: true,
    }
  });
  gsap.to('.index-message_ornament-02', {
    scrollTrigger: {
      trigger: '.index-message',
      start: '40% center',
      toggleClass: { targets: ".index-message_ornament-02", className: "is-scrolled" },
      once: true,
    }
  });
  gsap.to('.index-message_ornament-03', {
    scrollTrigger: {
      trigger: '.index-message',
      start: '40% center',
      toggleClass: { targets: ".index-message_ornament-03", className: "is-scrolled" },
      once: true,
    }
  });
  gsap.to('.index-politicsOpening_image', {
    scrollTrigger: {
      trigger: '.index-politicsOpening_image',
      start: '70% bottom',
      toggleClass: { targets: ".index-politicsOpening_image", className: "is-scrolled" },
      once: true,
    }
  });
  gsap.to('.index-profile_container', {
    scrollTrigger: {
      trigger: '.index-profile_container',
      start: 'top 30%',
      toggleClass: { targets: ".index-profile_container", className: "is-scrolled" },
      once: true,
    }
  });
  gsap.to('.index-profile_image', {
    scrollTrigger: {
      trigger: '.index-profile_image',
      start: 'top center',
      toggleClass: { targets: ".index-profile_image", className: "is-scrolled" },
      once: true,
    }
  });
  $(window).on('scroll',function(){
    let scroll_top = $(window).scrollTop();
    $('.l-header').each(function(){
      if( scroll_top > 70){
        $(this).addClass('is-scrolled');       
      }else{
        $(this).removeClass('is-scrolled');       
      }
    });
  });


  document.querySelector('.l-navTrigger').addEventListener('click', function() {
    this.classList.toggle('is-open');
    this.nextElementSibling.classList.toggle('is-open');
    this.parentNode.classList.toggle('is-open');
  });


  document.querySelectorAll('.l-nav_anchor').forEach(function(element) {
    element.addEventListener('click', function() {
      document.querySelector('.l-navTrigger').classList.remove('is-open');
      document.querySelector('.l-navTrigger').nextElementSibling.classList.remove('is-open');
      document.querySelector('.l-navTrigger').parentNode.classList.remove('is-open');

    })
  });


});

